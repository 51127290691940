'use client'

import { BellIcon } from '@heroicons/react/24/solid'
import { useState } from 'react'

import VendorReviewRequestList from '~/app/(app)/_modules/header/inbox/vendor-review-request-list'
import { Button } from '~/ui/shadcn/ui/button'
import { client } from '~/app/_trpc/client'
import {
	SheetTrigger,
	SheetContent,
	SheetHeader,
	SheetTitle,
	Sheet,
} from '~/ui/shadcn/ui/sheet'

import SupplementalGradeReviewRequestList from './supplemental-grade-review-request-list'

const InboxShelf = ({
	initialCount,
}: {
	initialCount: number
}) => {
	const trpcContext = client.useUtils()
	const [
		open,
		setOpen,
	] = useState(false)

	const {
		data: numNotifications, isInitialLoading, isError,
	} =
		client.reviewRequests.countReviewRequestNotifications.useQuery(undefined, {
			refetchInterval: 60_000,
			initialData: initialCount,
		})

	if (isInitialLoading || isError) {
		return null
	}

	const {
		data: orgPermissions,
	} = client.organizations.getOrgPermissions.useQuery()

	return (
		<Sheet
			open={open}
			onOpenChange={async (open) => {
				if (open) {
					await trpcContext.reviewRequests.getReviewRequestNotifications.refetch()
				}
				setOpen(open)
			}}
		>
			<SheetTrigger asChild={true}>
				<Button
					size="icon"
					className="relative size-8 bg-transparent text-black shadow-none hover:bg-tremor-brand-muted"
				>
					<BellIcon className="size-5 text-v2-colors-icon" />
					{numNotifications > 0 ? (
						<div className="absolute -end-2 -top-2 inline-flex size-5 items-center justify-center rounded-full bg-red-500 text-xs font-semibold text-white dark:border-gray-900">
							{numNotifications}
						</div>
					) : null}
				</Button>
			</SheetTrigger>
			<SheetContent className="w-full space-y-4 sm:max-w-3xl">
				<SheetHeader>
					<SheetTitle>
						Recent Notifications
					</SheetTitle>
				</SheetHeader>
				{orgPermissions?.generalContractorBenchmark ? (
					<VendorReviewRequestList
						onRoute={() => {
							setOpen(false)
						}}
					/>
				) : null}
				{orgPermissions?.generalContractorPrequal ? (
					<SupplementalGradeReviewRequestList
						onRoute={() => {
							setOpen(false)
						}}
					/>
				) : null}
			</SheetContent>
		</Sheet>
	)
}

export default InboxShelf
